import { DeleteOutlined } from '@ant-design/icons';
import { OnlineTicketChannelTypeLabel, OnlineTicketType } from '@kinderlabs-pos/shared-data-type';
import { getDateFormat } from '@kinderlabs-pos/shared-util';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Divider, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Fragment } from 'react';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';

export const KioskOnlineTicketSection = ({
	formik,
}: {
	formik: ReturnType<
		typeof useFormik<{
			tickets: OnlineTicketType[];
			submitMessage: string;
		}>
	>;
}) => {
	const customConfirm = useConfirm();
	const { t } = useKioskTranslation();

	return (
		<Stack
			component={'section'}
			flex={1}
			p={3}
			overflow={'auto'}>
			<List>
				{formik.values.tickets.map((ticket, idx) => (
					<Fragment key={ticket.externalTicketId}>
						<ListItem key={ticket.externalTicketId}>
							<Stack
								py={1}
								width='100%'
								flex={1}>
								<Typography
									variant='h6'
									sx={{
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										overflowX: 'hidden',
									}}>
									{ticket.ticketName}
								</Typography>
								<Typography
									color={'text.secondary'}
									variant='body1'>
									{`${OnlineTicketChannelTypeLabel[ticket.ticketChannel]} | #${
										ticket.externalTicketId
									}`}
								</Typography>
								<Typography
									color={'text.secondary'}
									variant='body1'>
									{`${getDateFormat(ticket.purchased)} ~ ${getDateFormat(ticket.expiration)}`}
								</Typography>
							</Stack>
							<IconButton
								size='large'
								onClick={() => {
									customConfirm(t('onlineTicket.deleteConfirm'), () => {
										formik.setValues((prev) => ({
											...prev,
											tickets: prev.tickets.filter(
												(t) => t.externalTicketId !== ticket.externalTicketId
											),
										}));
									});
								}}>
								<DeleteOutlined />
							</IconButton>
						</ListItem>
						<Divider />
					</Fragment>
				))}
			</List>
			{formik.values.tickets.length > 0 && (
				<Typography
					color={'text.secondary'}
					textAlign={'center'}>
					{t('onlineTicket.pleaseAdd')}
				</Typography>
			)}
		</Stack>
	);
};
