import {
	EasyPayMethodType,
	KioskInfoType,
	PaymentRequestInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { KioskElectronStoreState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { DialogContent, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { KioskBellSection } from './KioskBellSection';
import { KioskCardPaymentSection } from './KioskCardPaymentSection';
import { KioskPaymentResultSection } from './KioskPaymentResultSection';
import { KioskPaymentSelectSection } from './KioskPaymentSelectSection';
import { KioskTelephoneSection } from './KioskTelephoneSection';
import { KioskNoPaymentSection } from './KioskNoPaymentSection';
import { KioskEasyPaySelectSection } from './KioskEasyPaySelectSection';
import { KioskEasypayPaymentSection } from './KioskEasypayPaymentSection';
import { KioskMainPageStepsType, KioskPaymentMethodType } from '../../KioskMainPageType';
import { KioksUserInfoPage } from '../../../KioskUserInfoMappingPage';

type StepsType = 'telephoneOrBell' | 'paymentSelect' | 'payment' | 'paymentResult';

export const KioskFNBCartOrderDialog = ({
	open,
	closeDialog,
	kioskInfo,
}: {
	kioskInfo: KioskInfoType;
} & XlDialogProps) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const guestMemberInfo = useAtomValue(KioksUserInfoPage.finalUserInfo);
	const hasTelephoneOption = kioskInfo.telephoneOption !== 'NONE';
	const hasBellOption = !hasTelephoneOption && kioskInfo.bellOption !== 'NONE';

	const [orderStep, setOrderStep] = useState<KioskMainPageStepsType>(
		hasTelephoneOption || hasBellOption ? 'telephoneOrBell' : 'paymentSelect'
	);

	const [paymentMethod, setPaymentMethod] = useState<KioskPaymentMethodType>('CARD'); // 서브 결제수단

	const handleGoNextStep = () => {
		if (orderStep === 'telephoneOrBell') setOrderStep('paymentSelect');
	};

	const handleGoBackStep = () => {
		if (orderStep === 'cardPayment' || orderStep === 'easypaySelect') {
			setOrderStep('paymentSelect');
		} else if (orderStep === 'easypayPayment') {
			setOrderStep('easypaySelect');
		}
	};

	const selectPayment = (method: KioskPaymentMethodType) => {
		setPaymentMethod(method);
		switch (method) {
			case 'CARD':
			case 'VIRTUAL_CARD':
				setOrderStep('cardPayment');
				break;
			case 'NO_PAYMENT':
				setOrderStep('noPayment');
				break;
			case 'EASYPAY':
				setOrderStep('easypaySelect');
				break;
			// 나머지 간편결제 타입. case문으로 분류가 않아 디폴트로 설정
			default:
				setOrderStep('easypayPayment');
		}
	};

	const createKioskOrderPayment = OrderState.actions.useCreateKioskOrderPayment({
		storeInfo,
		deviceId: kioskInfo.id,
		guestMemberInfo,
	});

	const [orderId, setOrderId] = useState<string | undefined>(undefined);
	const handleSubmitPayment = async (paymentRequestData?: PaymentRequestInfoType) => {
		const result = await createKioskOrderPayment.mutateAsync({ paymentRequestData });
		setOrderId(result.order.id);

		setOrderStep('paymentResult');
	};

	const cartReducer = OrderState.cart.useDispatcher();

	useEffect(() => {
		return () => {
			cartReducer({ type: 'BELL', subAction: { type: 'REMOVE_BELL' } });
		};
	}, []);

	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const 영수증자동인쇄옵션켜졌는지 = localSettings?.receiptAutoPrint ?? false;

	return (
		<WithDialog.XlDialog
			open={open}
			maxWidth={'xl'}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 768, aspectRatio: '1/1.8' }}>
				<Stack
					height={'100%'}
					width={'100%'}
					flex={1}
					spacing={3}>
					<Suspense fallback={<FullSizeCircularProgress />}>
						{orderStep === 'telephoneOrBell' &&
							(hasTelephoneOption ? (
								<KioskTelephoneSection
									open={open}
									closeDialog={closeDialog}
									kioskInfo={kioskInfo}
									handleGoNextStep={handleGoNextStep}
								/>
							) : (
								<KioskBellSection
									open={open}
									closeDialog={closeDialog}
									kioskInfo={kioskInfo}
									handleGoNextStep={handleGoNextStep}
								/>
							))}
						{orderStep === 'paymentSelect' && (
							<KioskPaymentSelectSection
								open={open}
								closeDialog={closeDialog}
								kioskInfo={kioskInfo}
								selectPayment={selectPayment}
							/>
						)}
						{orderStep === 'easypaySelect' && (
							<KioskEasyPaySelectSection
								open={open}
								closeDialog={closeDialog}
								goBack={handleGoBackStep}
								kioskInfo={kioskInfo}
								selectPayment={selectPayment}
							/>
						)}
						{orderStep === 'cardPayment' && (
							<KioskCardPaymentSection
								kioskInfo={kioskInfo}
								storeInfo={storeInfo}
								goBack={handleGoBackStep}
								paymentMethod={paymentMethod as 'CARD' | 'VIRTUAL_CARD'}
								handleSubmitPayment={handleSubmitPayment}
							/>
						)}
						{orderStep === 'easypayPayment' && (
							<KioskEasypayPaymentSection
								kioskInfo={kioskInfo}
								storeInfo={storeInfo}
								goBack={handleGoBackStep}
								paymentMethod={paymentMethod as EasyPayMethodType}
								handleSubmitPayment={handleSubmitPayment}
							/>
						)}
						{orderStep === 'noPayment' && (
							<KioskNoPaymentSection
								kioskInfo={kioskInfo}
								storeInfo={storeInfo}
								goBack={handleGoBackStep}
								handleSubmitPayment={handleSubmitPayment}
							/>
						)}
						{orderStep === 'paymentResult' && orderId && (
							<KioskPaymentResultSection
								kioskInfo={kioskInfo}
								orderId={orderId}
								영수증자동인쇄옵션켜졌는지={영수증자동인쇄옵션켜졌는지}
							/>
						)}
					</Suspense>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
