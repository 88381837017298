import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import {
	AvailableBarcodeModelType,
	CustomerReceiptRequestDummy,
	DeviceType,
	ExchangeCouponRequestDummy,
	getDdiziDummy,
} from '@kinderlabs-pos/shared-data-type';
import {
	AdminKioskInfoState,
	KioskElectronStoreState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { KioskSettingsItem } from './commonKioskSettingsItem';

export const KioskReceiptPrinterTabPanel = ({}: {}) => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId, kioskId: deviceId }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const receiptPrinterList = kioskInfo?.receiptPrinterList ?? [];

	const printMode = kioskInfo?.printMode ?? 'ESCPOS';
	const CandidateCustomerPrinterInfos = receiptPrinterList.filter(
		(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
	);

	const handleClick금전함열기 = PrintReceiptState.useSendOpenBox({
		receiptPrinterList: CandidateCustomerPrinterInfos,
		printMode,
	});
	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();

	const selectBarcodeModel = (selected: AvailableBarcodeModelType) => {
		if (localSettings)
			saveLocalSettingsMutation.mutate({
				...localSettings,
				usingBarcodeModel: selected,
			});
	};

	const selectReceiptPrintingType = (selected: DeviceType) => {
		if (localSettings)
			saveLocalSettingsMutation.mutate({
				...localSettings,
				receiptPrintingType: selected,
			});
	};

	return (
		<Stack
			width={'100%'}
			flex={1}
			spacing={10}>
			<KioskSettingsItem
				label={'영수증 프린터 포트'}
				innerElement={
					<FormControl>
						<FormLabel id='rp-port'></FormLabel>
						<RadioGroup
							row
							name='rp-port'
							defaultValue='usb'>
							<FormControlLabel
								value='usb'
								control={<Radio />}
								label='USB'
							/>
							<FormControlLabel
								value='com1'
								control={<Radio />}
								label='COM1'
								disabled
							/>
							<FormControlLabel
								value='com2'
								control={<Radio />}
								label='COM2'
								disabled
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<KioskSettingsItem
				label={'샘플 영수증'}
				innerElement={
					<Stack
						direction={'row'}
						spacing={3}>
						<Button
							variant='outlined'
							onClick={async () => {
								handleClick금전함열기();
							}}>
							현금함
						</Button>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCustomerPrinterInfos) {
									await ReactRPIPC.invoke고객영수증출력({
										...CustomerReceiptRequestDummy,
										barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
										hardwareInfo: {
											deviceType: 'KIOSK',
											printerInfo: {
												...printerInfo,
												deviceType: localSettings ? localSettings.receiptPrintingType : 'KIOSK',
											},
											printMode: printMode,
										},
									});
								}
							}}>
							고객영수증
						</Button>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCustomerPrinterInfos) {
									await ReactRPIPC.invoke교환권출력({
										...ExchangeCouponRequestDummy,
										barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
										hardwareInfo: {
											deviceType: 'KIOSK',
											printerInfo: {
												...printerInfo,
												deviceType: localSettings ? localSettings.receiptPrintingType : 'KIOSK',
											},
											printMode: printMode,
										},
									});
								}
							}}>
							교환권
						</Button>
					</Stack>
				}
			/>
			<KioskSettingsItem
				label={'샘플 영수증'}
				innerElement={
					<Stack
						direction={'row'}
						spacing={3}>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCustomerPrinterInfos) {
									await ReactRPIPC.invoke영수증띠지출력({
										ddiziInfo: getDdiziDummy({ storeId, deviceId }),
										barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
										hardwareInfo: {
											deviceType: 'KIOSK',
											printerInfo: {
												...printerInfo,
												deviceType: localSettings ? localSettings.receiptPrintingType : 'KIOSK',
											},
											printMode: printMode,
										},
									});
								}
							}}>
							영수증띠지
						</Button>
					</Stack>
				}
			/>
			<KioskSettingsItem
				label={'바코드 모델 설정'}
				innerElement={
					<Stack
						direction={'row'}
						spacing={3}>
						<Select
							sx={{ marginRight: 2 }}
							defaultValue={localSettings?.usingBarcodeModel}
							onChange={(e) => {
								selectBarcodeModel(e.target.value as AvailableBarcodeModelType);
							}}>
							<MenuItem value='QRCODE'>모델 1 (QRCODE, 권장)</MenuItem>
							<MenuItem value='PDF417'>모델 2 (PDF417, 권장)</MenuItem>
							<MenuItem value='CODE128'>모델 3 (CODE128)</MenuItem>
							<MenuItem value='CODE93'>모델 4 (CODE93)</MenuItem>
							<MenuItem value='CODE39'>모델 5 (CODE39)</MenuItem>
							<MenuItem value='NONE'>미사용</MenuItem>
						</Select>
						<Button
							variant='outlined'
							onClick={async () => {
								console.log(`모델 ${localSettings?.usingBarcodeModel} 로 설정됨`);
							}}>
							설정
						</Button>
					</Stack>
				}
			/>
			<KioskSettingsItem
				label={'글자 수 설정'}
				innerElement={
					<Stack
						direction={'row'}
						spacing={3}>
						<Select
							sx={{ marginRight: 2 }}
							defaultValue={localSettings?.receiptPrintingType}
							onChange={(e) => {
								selectReceiptPrintingType(e.target.value as DeviceType);
							}}>
							<MenuItem value='KIOSK'>타입 1(48자/1줄)</MenuItem>
							<MenuItem value='POS'>타입 2(42자/1줄)</MenuItem>
						</Select>
						<Button
							variant='outlined'
							onClick={async () => {
								console.log(`설정됨`);
							}}>
							설정
						</Button>
					</Stack>
				}
			/>
		</Stack>
	);
};
