import { WarningOutlined } from '@ant-design/icons';
import {
	EasyPayMethodType,
	KioskInfoType,
	PaymentRequestInfoType,
	StoreDetailInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { KioskElectronStoreState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import PersonIcon from '@mui/icons-material/Person';
import { alpha, Button, DialogContent, Stack, Typography } from '@mui/material';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { KioskExitPage } from '.';
import { KioskCardPaymentSection } from '../KioskBoardPage/cart/KioskCardPaymentSection';
import { KioskPaymentResultSection } from '../KioskBoardPage/cart/KioskPaymentResultSection';
import { KioskPaymentSelectSection } from '../KioskBoardPage/cart/KioskPaymentSelectSection';
import { KioskNoPaymentSection } from '../KioskBoardPage/cart/KioskNoPaymentSection';
import { KioskEasyPaySelectSection } from '../KioskBoardPage/cart/KioskEasyPaySelectSection';
import { KioskEasypayPaymentSection } from '../KioskBoardPage/cart/KioskEasypayPaymentSection';
import { KioskMainPageStepsType, KioskPaymentMethodType } from '../KioskMainPageType';

export const KioskExitCartOrderDialog = ({
	open,
	closeDialog,
	kioskInfo,
}: {
	kioskInfo: KioskInfoType;
} & XlDialogProps) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { remainingPay } = useAtomValue(OrderState.aggregate.payments);
	const [orderStep, setOrderStep] = useState<KioskMainPageStepsType>('paymentSelect');
	const [paymentMethod, setPaymentMethod] = useState<KioskPaymentMethodType>('CARD'); // 서브 결제수단

	const handleGoBackStep = () => {
		if (orderStep === 'cardPayment' || orderStep === 'easypaySelect') {
			setOrderStep('paymentSelect');
		} else if (orderStep === 'easypayPayment') {
			setOrderStep('easypaySelect');
		}
	};

	const selectPayment = (method: KioskPaymentMethodType) => {
		setPaymentMethod(method);
		switch (method) {
			case 'CARD':
			case 'VIRTUAL_CARD':
				setOrderStep('cardPayment');
				break;
			case 'NO_PAYMENT':
				setOrderStep('noPayment');
				break;
			case 'EASYPAY':
				setOrderStep('easypaySelect');
				break;
			// 나머지 간편결제 타입. case문으로 분류가 않아 디폴트로 설정
			default:
				setOrderStep('easypayPayment');
		}
	};

	const createKioskOrderPayment = OrderState.actions.useCreateKioskOrderPayment({
		storeInfo,
		deviceId: kioskInfo.id,
	});

	const [orderId, setOrderId] = useState<string | undefined>(undefined);
	const handleSubmitPayment = async (paymentRequestData?: PaymentRequestInfoType) => {
		const result = await createKioskOrderPayment.mutateAsync({ paymentRequestData });
		setOrderId(result.order.id);

		setOrderStep('paymentResult');
	};

	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const 영수증자동인쇄옵션켜졌는지 = localSettings?.receiptAutoPrint ?? false;

	// Dialog Open 처리하기
	const setDialogOpened = useSetAtom(KioskExitCartOrderDialog.opened);
	useEffect(() => {
		setDialogOpened(true);
		return () => setDialogOpened(false);
	}, []);

	return (
		<WithDialog.XlDialog
			open={open}
			maxWidth={'xl'}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 768, aspectRatio: '1/1.8' }}>
				<Stack
					height={'100%'}
					width={'100%'}
					flex={1}
					spacing={3}>
					<Suspense fallback={<FullSizeCircularProgress />}>
						{remainingPay > 0 ? (
							<>
								{orderStep === 'paymentSelect' && (
									<KioskPaymentSelectSection
										open={open}
										closeDialog={closeDialog}
										kioskInfo={kioskInfo}
										selectPayment={selectPayment}
									/>
								)}
								{orderStep === 'easypaySelect' && (
									<KioskEasyPaySelectSection
										open={open}
										closeDialog={closeDialog}
										goBack={handleGoBackStep}
										kioskInfo={kioskInfo}
										selectPayment={selectPayment}
									/>
								)}
								{orderStep === 'cardPayment' && (
									<KioskCardPaymentSection
										kioskInfo={kioskInfo}
										storeInfo={storeInfo}
										goBack={handleGoBackStep}
										paymentMethod={paymentMethod as 'CARD' | 'VIRTUAL_CARD'}
										handleSubmitPayment={handleSubmitPayment}
									/>
								)}
								{orderStep === 'easypayPayment' && (
									<KioskEasypayPaymentSection
										kioskInfo={kioskInfo}
										storeInfo={storeInfo}
										goBack={handleGoBackStep}
										paymentMethod={paymentMethod as EasyPayMethodType}
										handleSubmitPayment={handleSubmitPayment}
									/>
								)}
								{orderStep === 'noPayment' && (
									<KioskNoPaymentSection
										kioskInfo={kioskInfo}
										storeInfo={storeInfo}
										goBack={handleGoBackStep}
										handleSubmitPayment={handleSubmitPayment}
									/>
								)}
								{orderStep === 'paymentResult' && orderId && (
									<KioskPaymentResultSection
										kioskInfo={kioskInfo}
										orderId={orderId}
										영수증자동인쇄옵션켜졌는지={영수증자동인쇄옵션켜졌는지}
									/>
								)}
							</>
						) : (
							<>
								{orderStep !== 'paymentResult' && (
									<KioskExitWithoutChargeSection
										storeInfo={storeInfo}
										kioskInfo={kioskInfo}
										closeDialog={closeDialog}
										setOrderId={(val) => setOrderId(val)}
										setOrderStep={(val) => setOrderStep(val)}
									/>
								)}
								{orderStep === 'paymentResult' && orderId && (
									<KioskPaymentResultSection
										kioskInfo={kioskInfo}
										orderId={orderId}
										영수증자동인쇄옵션켜졌는지={영수증자동인쇄옵션켜졌는지}
									/>
								)}
							</>
						)}
					</Suspense>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
KioskExitCartOrderDialog.opened = atom<boolean>(false);

interface IKioskExitWithouthargeSectionProps {
	storeInfo: StoreDetailInfoType;
	kioskInfo: KioskInfoType;
	setOrderId: (val: string) => void;
	setOrderStep: (val: KioskMainPageStepsType) => void;
	closeDialog?: () => void;
}
const KioskExitWithoutChargeSection: React.FC<IKioskExitWithouthargeSectionProps> = ({
	storeInfo,
	kioskInfo,
	closeDialog,
	setOrderId,
	setOrderStep,
}) => {
	const orderList = useAtomValue(KioskExitPage.selectedOrderListAtom);
	const selectableDdiziList = orderList.map((order) => order.ddizis).flat();
	const selectedCartLines = useAtomValue(OrderState.cart.lines.excessChargeSelector);

	const createKioskOrderPayment = OrderState.actions.useCreateKioskOrderPayment({
		storeInfo,
		deviceId: kioskInfo.id,
	});

	const handleSubmitPayment = async (paymentRequestData?: PaymentRequestInfoType) => {
		const result = await createKioskOrderPayment.mutateAsync({ paymentRequestData });
		setOrderId(result.order.id);
		setOrderStep('paymentResult');
	};

	const filteredDdiziList = useMemo(() => {
		return selectableDdiziList.filter((ddizi) =>
			selectedCartLines.map((cl) => cl.ddiziUsageId).includes(ddizi.id)
		);
	}, [selectedCartLines]);

	return (
		<Stack height={'100%'}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>{`선택된 ${filteredDdiziList.length}개의 티켓을 퇴장 처리 합니다.`}</Typography>
			</Stack>
			<Stack
				flex={1}
				height={'100%'}
				width={'100%'}
				overflow={'auto'}
				py={5}
				spacing={5}>
				{filteredDdiziList.map((ddizi, idx) => (
					<Stack
						key={ddizi.id}
						direction={'row'}
						alignItems={'center'}
						spacing={8}>
						<Typography variant='h5'>{idx + 1}</Typography>
						<Stack>
							<Stack
								direction={'row'}
								alignItems={'center'}
								flex={1}
								spacing={1}>
								{storeInfo.name && <Typography variant={'h6'}>{`[${storeInfo.name}]`}</Typography>}
								<Typography
									variant={'h6'}
									component={'span'}
									flex={1}
									sx={{
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										overflowX: 'hidden',
									}}>
									{`${ddizi.name} ${ddizi.type === 'GROUP_TICKET' ? `(${ddizi.multiple} 명)` : ''}`}
								</Typography>
							</Stack>
							<Typography
								variant={'body1'}
								color={'text.secondary'}>{`#${ddizi.id}`}</Typography>
							<Stack
								direction={'row'}
								alignItems={'center'}
								spacing={2}>
								<Typography variant={'h6'}>{`${getDateFormat(
									ddizi.publishInfo.published
								)}`}</Typography>
							</Stack>
							{ddizi.publishInfo.userName && ddizi.publishInfo.userTelephone && (
								<Stack
									direction={'row'}
									alignItems={'center'}
									spacing={1}>
									<PersonIcon sx={{ fontSize: 40 }} />
									<Typography
										variant={'h6'}
										component={'span'}>
										{ddizi.publishInfo.userName}
									</Typography>
									<Typography
										variant={'body1'}
										component={'span'}>
										({getTelephoneNumberWithDash(ddizi.publishInfo.userTelephone)})
									</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
				))}
			</Stack>
			<Stack
				sx={(theme) => ({
					width: '100%',
					p: 2,
					bgcolor: alpha(theme.palette.warning.light, 0.1),
					color: theme.palette.warning.main,
				})}>
				<Typography
					color={'inherit'}
					textAlign={'center'}
					variant='h5'>
					<WarningOutlined style={{ marginRight: 16 }} />
					{'추가 요금 없이 퇴장 처리 됩니다.'}
				</Typography>
			</Stack>
			<Stack
				width={'100%'}
				direction={'row'}
				py={3}
				spacing={3}>
				<Button
					variant='outlined'
					fullWidth
					onClick={closeDialog}>
					결제취소
				</Button>
				<Button
					variant='contained'
					fullWidth
					onClick={() => handleSubmitPayment()}>
					퇴장하기
				</Button>
			</Stack>
		</Stack>
	);
};
