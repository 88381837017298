import { PrinterOutlined, WarningOutlined } from '@ant-design/icons';
import { useSortedCartLines } from '@kinderlabs-pos/feature/pos/cart-table';
import { ExchangeCouponType, KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원, sleep } from '@kinderlabs-pos/shared-util';
import {
	AdminProductInfoState,
	ExchangeCouponPrintEventHandler,
	OrderQueryState,
	OrderState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { SimpleScrollbar } from '@kinderlabs-pos/ui-atoms';
import { alpha, Button, debounce, Divider, List, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CircularTimeoutButton } from 'apps/kiosk-main/src/components/CircularTimeoutButton';
import { useKioskRouter } from 'apps/kiosk-main/src/utils/useKioskRouter';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { KioskCartSimpleItem } from './KioskCartSimpleItem';
import { useKioskTranslation } from '../../../../const/useKioskTranslation';

export const KioskPaymentResultSection = ({
	kioskInfo,
	orderId,
	영수증자동인쇄옵션켜졌는지,
}: {
	kioskInfo: KioskInfoType;
	orderId: string;
	영수증자동인쇄옵션켜졌는지: boolean;
}) => {
	const { t, kioskI18N } = useKioskTranslation();
	const { navigate홈으로, navigate온라인권발권 } = useKioskRouter();
	const { data: orderQueryData } = useQuery({
		...OrderQueryState.keys.detailByOrderId(orderId),
		refetchOnWindowFocus: true,
	});
	const [timeoutToExit, setTimeoutToExit] = useState(15);
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;

	const 영수증수동출력 = PrintReceiptState.use영수증수동출력({ receiptType: 'ORDER' });

	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId });

	const { print영수증띠지Async } = PrintReceiptState.useReceiptDdiziPrinter({
		storeId,
		deviceType: 'KIOSK',
	});

	const { printKiosk주문서Async } = PrintReceiptState.useKiosk주문증Printer({
		storeId,
		deviceType: 'KIOSK',
	});

	const [ddiziPrintFinished, setDdiziPrintFinished] = useState(false);

	const [receiptPrinted, setReceiptPrinted] = useState(false);
	const handleClickReceiptPrint = debounce(async () => {
		if (orderQueryData) {
			await 영수증수동출력({
				order: orderQueryData,
				payments: orderQueryData.payments,
			});

			setReceiptPrinted(true);
			// await printCafe주문서Async({ order: orderQueryData });
		}
	}, 1000);

	// 영수증 자동 인쇄
	useEffect(() => {
		if (영수증자동인쇄옵션켜졌는지 && ddiziPrintFinished)
			setTimeout(() => {
				handleClickReceiptPrint();
			}, 500);
	}, [영수증자동인쇄옵션켜졌는지, ddiziPrintFinished]);

	const exchangeCoupons = orderQueryData?.exchangeCoupons || [];

	// 영수증 프린트도 뽑을수 있어야지. 추후 라벨 프린터도 추가해야할텐데 그 때가 되면 UI/UX 변경이 필요할 듯
	useEffect(() => {
		(async () => {
			if (orderQueryData && kioskInfo.type === 'FANDB' && kioskInfo.bellOption === 'NONE') {
				// 여기 넣어야 해
				await printKiosk주문서Async({ order: orderQueryData });
			}

			if (orderQueryData && orderQueryData.ddizis.length === 0) {
				setDdiziPrintFinished(true);
				return;
			}

			for (const ddizi of orderQueryData?.ddizis || []) {
				try {
					await print영수증띠지Async({
						ddizi,
					});
					setTimeoutToExit(15);
				} finally {
					//
				}
			}

			setDdiziPrintFinished(true);
		})();
	}, [orderQueryData]);

	// const cartLines = orderQueryData?.cart.lines || [];
	const cartLines = useAtomValue(OrderState.cart.selector).lines;

	const sortCartLines = useSortedCartLines();
	const sortedCartLines = sortCartLines(cartLines);
	const sortedCartLinesForTarget = sortedCartLines.filter((cl) => !cl.targetCartLineId);

	const { totalToPay } = OrderState.cart.utils.getAggregate({ lines: sortedCartLines });

	const 매표소키오스크다 = kioskInfo.type === 'TICKETS';
	const handle온라인권으로 = () => {
		navigate온라인권발권();
	};

	return (
		<Stack
			height={'100%'}
			overflow={'auto'}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>
					{kioskInfo.type !== 'JUNGSAN' ? t('payment.completed') : t('payment.exitCompleted')}
				</Typography>
			</Stack>
			<Stack
				flex={1}
				spacing={3}
				alignItems={'center'}
				overflow={'auto'}>
				<Stack
					width={'100%'}
					alignItems={'center'}
					py={2}
					sx={(theme) => ({ bgcolor: alpha(theme.palette.primary.main, 0.1) })}>
					<Typography variant={'body2'}>{t('payment.orderNum')}</Typography>
					<Typography variant={'h4'}>{orderQueryData?.dailySeq}</Typography>
				</Stack>
				<Stack
					width={'100%'}
					overflow={'auto'}
					flex={1}
					py={2}>
					<SimpleScrollbar>
						<Divider flexItem />
						<List
							disablePadding
							sx={{ width: '100%', flex: 1, overflow: 'auto' }}>
							{sortedCartLinesForTarget.map((cartLine, idx) => (
								<KioskCartSimpleItem
									cartLine={cartLine}
									optionsCartLines={sortedCartLines.filter(
										(cl) => cl.targetCartLineId === cartLine.id
									)}
									key={idx}
								/>
							))}
						</List>
					</SimpleScrollbar>
				</Stack>
				<Divider flexItem />
				<Stack
					width={'100%'}
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<Typography variant={'h5'}>{t('common.total')}</Typography>
					<Typography variant={'h5'}>{numberWithCommasAnd원(totalToPay, kioskI18N)}</Typography>
				</Stack>
				{exchangeCoupons.length > 0 && <ExchangeCouponPrintEventHandler deviceType='KIOSK' />}
				<Stack
					sx={(theme) => ({
						width: '100%',
						p: 2,
						bgcolor: alpha(theme.palette.warning.light, 0.1),
						color: theme.palette.warning.main,
					})}>
					<Typography
						color={'inherit'}
						textAlign={'center'}
						variant='h5'>
						<WarningOutlined />
						{t('payment.cardWarning')}
					</Typography>
				</Stack>
			</Stack>
			<Stack
				direction={'row'}
				py={3}
				spacing={3}>
				<Button
					variant='outlined'
					fullWidth
					disabled={!ddiziPrintFinished}
					endIcon={
						<CircularTimeoutButton
							timeout={timeoutToExit}
							onTimeoutCallback={navigate홈으로}
						/>
					}
					onClick={() => {
						navigate홈으로();
					}}>
					{t('common.return')}
				</Button>
				<Button
					variant='contained'
					fullWidth
					disabled={receiptPrinted || 영수증자동인쇄옵션켜졌는지}
					startIcon={<PrinterOutlined style={{ fontSize: '150%' }} />}
					onClick={handleClickReceiptPrint}>
					{receiptPrinted ? t('receipt.completed') : t('receipt.printing')}
				</Button>
			</Stack>
			{매표소키오스크다 && orderQueryData?.guestMemberInfo && (
				<Button
					sx={{ mb: 3 }}
					variant='contained'
					fullWidth
					disabled={!ddiziPrintFinished}
					onClick={handle온라인권으로}>
					{t('payment.toOnlineTicket')}
				</Button>
			)}
		</Stack>
	);
};

// const useExchangeCouponPrint = ({ exchangeCoupons }: { exchangeCoupons: ExchangeCouponType[] }) => {
// 	const { print쿠폰Async } = PrintReceiptState.useExchangePrinter({ deviceType: 'KIOSK' });
// 	const [exchangeCouponsPrinting, setExchangeCouponsPrinting] = useState<{ completed: boolean }[]>(
// 		[]
// 	);

// 	useEffect(() => {
// 		(async () => {
// 			for (const exchangeCoupon of exchangeCoupons) {
// 				try {
// 					await print쿠폰Async({ coupon: exchangeCoupon });
// 					await sleep(1 * 1000);
// 				} catch {
// 					// ignored
// 				} finally {
// 					setExchangeCouponsPrinting((prev) => [...prev, { completed: true }]);
// 				}
// 			}
// 		})();
// 	}, [exchangeCoupons]);

// 	return exchangeCouponsPrinting;
// };
