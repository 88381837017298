import {
	EasyPayMethodType,
	KioskInfoType,
	PaymentRequestInfoType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { sleep } from '@kinderlabs-pos/shared-util';
import { OrderState, PaymentState } from '@kinderlabs-pos/state';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { KioskPaymentMethodType } from '../../KioskMainPageType';
import * as Yup from 'yup';
import BarcodeScanGif from 'assets/barcode-scan.gif';
import { QRInput, SubmitButton } from '@kinderlabs-pos/ui-atoms';

const RETRY_LIMIT = 3;
export const KioskEasypayPaymentSection = ({
	goBack,
	storeInfo,
	kioskInfo,
	paymentMethod,
	handleSubmitPayment,
}: {
	goBack: () => void;
	storeInfo: StoreInfoType;
	kioskInfo: KioskInfoType;
	paymentMethod: Extract<KioskPaymentMethodType, EasyPayMethodType | 'EASYPAY'>;
	handleSubmitPayment: (paymentRequestInfo: PaymentRequestInfoType) => Promise<void>;
}) => {
	const { totalToPay } = useAtomValue(OrderState.aggregate.cart);

	const { handleSubmit, isSubmitting, isValid, values, handleChange, setValues, errors } =
		PaymentState.actions.submit.usePaymentFormik<{
			method: EasyPayMethodType | 'EASYPAY';
			customerId: string;
		}>({
			storeId: storeInfo.id,
			posId: kioskInfo.id,
			onClose: () => {},
			initialValues: {
				method: 'EASYPAY',
				customerId: '',
			},
			handleSubmitPayment,
			validationSchema: Yup.object().shape({
				customerId: Yup.string().required().min(1),
			}),
			getHandlePaymentArgs: (values) => ({
				method: values.method as EasyPayMethodType,
				amount: totalToPay,
				installment: 0,
				customerId: values.customerId,
			}),
		});

	const paymentPM강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	// const { 이미꽂힌카드가있는지, 이미꽂혀있는카드정보검사하기 } = use이미꽂힌카드가있는지확인한다();
	// useEffect(() => {
	// 	if (이미꽂힌카드가있는지 === 'NO') {
	// 		(async () => {
	// 			handleSubmit();
	// 		})();
	// 	}

	// 	return () => {
	// 		paymentPM강제취소();
	// 	};
	// }, [이미꽂힌카드가있는지]);

	const [safeRunningCheck, setSafeRunningCheck] = useState(false);
	useEffect(() => {
		(async () => {
			try {
				setSafeRunningCheck(true);
				await handleSubmit();
			} finally {
				setTimeout(() => setSafeRunningCheck(false), 1200);
			}
		})();

		return () => {
			paymentPM강제취소();
		};
	}, []);

	const { isServerError, isRetrying } = useRetryFailedPayment({
		handleSubmitPayment,
		failedPaymentRequestData: values.failedPaymentRequestData,
		handleCompleteRefund중복결제: () => {
			setValues({ ...values, failedPaymentRequestData: undefined });
			goBack();
		},
	});

	return (
		<Stack flex={1}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>{'간편 결제'}</Typography>
			</Stack>
			{isServerError &&
				(isRetrying ? (
					<FullSizeCircularProgress />
				) : (
					<Stack
						flex={1}
						alignItems={'center'}
						justifyContent={'center'}
						width={'100%'}>
						<FullSizeCircularProgress />
						<Typography variant={'subtitle1'}>서버 에러입니다.</Typography>
						<Typography variant={'subtitle1'}>결제를 취소하고 돌아갑니다.</Typography>
					</Stack>
				))}
			{/* {!values.failedPaymentRequestData && (
				<Stack
					flex={1}
					width={'100%'}
					alignItems={'center'}
					py={5}
					spacing={5}>
					<Typography
						variant='h6'
						color={'text.secondary'}>
						카드를 카드리더기에 삽입하거나
						<br />
						휴대폰을 리더기에 태그해주세요.
					</Typography>
					<Stack
						width={'100%'}
						height={'100%'}
						justifyContent={'center'}
						alignItems={'center'}>
						<Stack
							width={480}
							height={480}>
							<카드삽입Image />
						</Stack>
					</Stack>
				</Stack>
			)} */}
			<Stack
				direction={'column'}
				flex={1}>
				<Box height={'100%'}>
					<Stack
						p={3}
						height={'100%'}
						alignItems={'center'}
						justifyContent={'center'}
						spacing={1}>
						<Stack
							spacing={2}
							alignItems={'center'}>
							<BarcodeScan />
							<Typography
								variant={'h5'}
								color={'secondary'}>
								바코드, QR을 읽혀주세요
							</Typography>
							<form onSubmit={handleSubmit}>
								<Stack
									spacing={1}
									direction={'row'}>
									<QRInput
										name={'customerId'}
										value={values.customerId}
										autoFocus
										onChange={handleChange('customerId')}
									/>
									<SubmitButton
										type='submit'
										isSubmitting={isSubmitting}
										disabled={!isValid || isSubmitting}
										variant={'contained'}>
										결제하기
									</SubmitButton>
								</Stack>
							</form>
						</Stack>
						<Stack>
							<Typography
								variant='subtitle1'
								color={'error'}>
								{errors.submit}
							</Typography>
						</Stack>
					</Stack>
				</Box>
			</Stack>
			<Stack alignItems={'center'}>
				<Typography
					variant='subtitle1'
					color={'text.secondary'}>
					{!isServerError
						? errors.submit || 'ㅤ'
						: !isRetrying
						? errors.submit || 'ㅤ'
						: '결제정보 전송 중'}
				</Typography>
			</Stack>
			<Stack
				width={'100%'}
				direction={'row'}
				py={3}
				spacing={3}>
				<Button
					variant='outlined'
					fullWidth
					disabled={isSubmitting || isRetrying || safeRunningCheck}
					onClick={goBack}>
					돌아가기
				</Button>
			</Stack>
		</Stack>
	);
};
const BarcodeScan = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${BarcodeScanGif})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '120px',
	height: '120px',
}));

const useRetryFailedPayment = ({
	handleSubmitPayment,
	failedPaymentRequestData,
	handleCompleteRefund중복결제,
}: {
	handleSubmitPayment: (paymentRequestInfo: PaymentRequestInfoType) => Promise<void>;
	failedPaymentRequestData?: PaymentRequestInfoType;
	handleCompleteRefund중복결제: () => void;
}) => {
	const handleRefund중복결제 = PaymentState.actions.refund.useRefundOfficial중복결제();
	const [retry, setRetry] = useState(1);

	useEffect(() => {
		console.log(retry, failedPaymentRequestData);

		(async () => {
			try {
				await sleep(3 * 1000);
				if (failedPaymentRequestData) {
					if (retry < RETRY_LIMIT) {
						// 3번까지 재시도한다.
						await handleSubmitPayment(failedPaymentRequestData);
					} else {
						// 3번까지 재시도하고 안 되면 중복결제를 취소한다.
						const response = await handleRefund중복결제({ paymentInfo: failedPaymentRequestData });
						handleCompleteRefund중복결제();

						setRetry(1);
					}
				}
			} catch (err) {
				Sentry.captureException(err);

				Sentry.captureException(
					`키오스크 결제 실패 (Store ID : ${failedPaymentRequestData?.storeId}) // ` +
						JSON.stringify(failedPaymentRequestData)
				);
				setRetry((prev) => prev + 1);
			}
		})();
	}, [retry, failedPaymentRequestData]);

	const isServerError = failedPaymentRequestData;
	const isRetrying = isServerError && retry < RETRY_LIMIT;

	return { isServerError, isRetrying };
};
