import { Avatar, Backdrop, Box, Button, Stack, styled, Typography } from '@mui/material';
// import defaultBackgroundImage from 'assets/kiosk-background.jpg';
import { WarningOutlined } from '@ant-design/icons';
import { KioskInfoType, KioskInfoTypeRule, KioskTypeLabel } from '@kinderlabs-pos/shared-data-type';
import {
	AdminKioskInfoState,
	AdminStoreInfoState,
	authState,
	ExitQueryState,
	KioskElectronStoreState,
	PaymentState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { useQuery } from '@tanstack/react-query';
import entry from 'assets/entry.jpg';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import ChinaFlag from '../assets/free-icon-china.png';
import JapanFlag from '../assets/free-icon-japan.png';
import SouthKoreaFlag from '../assets/free-icon-south-korea.png';
import EnglishFlag from '../assets/free-icon-united-kingdom.png';
import { useKioskChangeLanguague, useKioskTranslation } from '../const/useKioskTranslation';
import { useKioskRouter } from '../utils/useKioskRouter';
import { KioksUserInfoPage } from './KioskUserInfoMappingPage';

export const HomeForEntery: React.FC = () => {
	// JIN : atom 의 try catch 에서 정상 동작하지 않아 우회할수가 없다. 어쩔수없이 예외적으로 사용하는 로직... ㅠㅠㅠㅠ
	const savedKioskInfo = StoreInfoState.useKioskLoginInfo();

	return (
		<Stack
			width={'100%'}
			height={'100%'}>
			<Stack
				width={'100%'}
				flex={1}
				position='relative'>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<전광판ImageWrapper
						storeId={savedKioskInfo?.storeId}
						kioskInfoId={savedKioskInfo?.deviceId}
					/>
				</Suspense>
			</Stack>
		</Stack>
	);
};

const 전광판ImageWrapper = ({
	storeId,
	kioskInfoId,
}: {
	storeId?: number;
	kioskInfoId?: number;
}) => {
	const [isCardInserted, setIsCardInserted] = useState(false);
	const { t, kioskI18N } = useKioskTranslation();
	const 외국어사용중 = kioskI18N !== 'ko';
	const setFinalUser = useSetAtom(KioksUserInfoPage.finalUserInfo);
	useEffect(() => {
		setFinalUser(undefined);
	}, []);

	const auth = useAtomValue(authState.user);
	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId, kioskId: kioskInfoId }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const salesStatus = kioskInfo?.status ?? 'BEFORE_OPEN';

	const get카드삽입여부 = PaymentState.actions.submit.useHandle카드삽입여부확인하기({
		vanCompany: kioskInfo?.vanCompany ?? 'NICE',
	});
	const isMemberInfoRequiredForFABKiosk =
		KioskElectronStoreState.useKioskMainPageLocalSettings().data?.isMemberInfoRequiredForFABKiosk;
	const 카드삽입여부검사 =
		KioskElectronStoreState.useKioskMainPageLocalSettings().data?.카드삽입여부검사;

	const isSignupPageRequiredFor매표소Kiosk = kioskInfo?.signupInfo;

	useEffect(() => {
		if (!kioskInfo) return;
		// 동탄점 키오스크 몽키패치
		else if (kioskInfo.id === 35) return;
		else if (!카드삽입여부검사) return;

		ExitQueryState.invalidateDdiziQueries();
		const interval = setInterval(async () => {
			const { isInserted } = await get카드삽입여부();
			console.log('카드 삽입여부 검사', isInserted);

			setIsCardInserted(isInserted || false);
		}, 4 * 1000);

		return () => clearInterval(interval);
	}, [kioskInfo, 카드삽입여부검사]);

	const { navigate퇴장페이지, navigateUserInfoPage, navigateKioskBoardPageByKioskType } =
		useKioskRouter();

	const canEnterNavigate = () => {
		if (isCardInserted) return false;
		if (
			!auth ||
			!kioskInfoId ||
			!kioskInfo ||
			// 키오스크 보드가 필요한데 보드가 없다면
			(KioskInfoTypeRule.hasBoard[kioskInfo.type] && !kioskInfo.kioskBoardId)
		)
			return false;
		if (['BEFORE_OPEN', 'CLOSED', 'PAUSED'].includes(salesStatus)) return false;

		return true;
	};

	const handleClick엔트리 = () => {
		if (canEnterNavigate() && kioskInfo && kioskInfo.type !== 'TICKETS') {
			// 매표소는 다른 방식으로 들어감
			switch (kioskInfo.type) {
				case 'JUNGSAN':
					navigate퇴장페이지();
					break;
				case 'FANDB':
					isMemberInfoRequiredForFABKiosk && !외국어사용중
						? navigateUserInfoPage('FANDB')
						: navigateKioskBoardPageByKioskType('FANDB');
					break;
				case 'CUSTOMER_MAPPING':
					break;
			}
		}
	};

	const imageUrl = (() => {
		switch (kioskI18N) {
			case 'eng':
				return kioskInfo?.entryImageUrlEng ?? kioskInfo?.entryImageUrl;
			case 'jpn':
				return kioskInfo?.entryImageUrlJpn ?? kioskInfo?.entryImageUrl;
			case 'chn':
				return kioskInfo?.entryImageUrlChn ?? kioskInfo?.entryImageUrl;
		}

		return kioskInfo?.entryImageUrl;
	})();

	return (
		<전광판Image backgroundImage={imageUrl}>
			<Stack
				width='100%'
				height={'100%'}>
				<Stack
					flex={1}
					onClick={kioskInfo?.type !== 'TICKETS' ? handleClick엔트리 : undefined}
				/>
				{kioskInfo?.type === 'TICKETS' && (
					<Stack
						width='100%'
						direction={'row'}
						p={4}
						spacing={4}>
						<Button
							onClick={() =>
								isSignupPageRequiredFor매표소Kiosk && !외국어사용중
									? navigateUserInfoPage('ONLINE-TICKETS')
									: navigateKioskBoardPageByKioskType('ONLINE-TICKETS')
							}
							variant={'outlined'}
							sx={{ bgcolor: 'white', color: 'black', borderColor: 'black', height: 180 }}
							fullWidth>
							<Typography
								color={'inherit'}
								variant='h3'>
								{t('tickets.온라인발권')}
							</Typography>
						</Button>
						{kioskInfo.kioskBoardId && (
							<Button
								sx={{ bgcolor: 'white', color: 'black', borderColor: 'black', height: 180 }}
								onClick={() =>
									isSignupPageRequiredFor매표소Kiosk && !외국어사용중
										? navigateUserInfoPage('TICKETS')
										: navigateKioskBoardPageByKioskType('TICKETS')
								}
								variant={'outlined'}
								fullWidth>
								<Typography
									color={'inherit'}
									variant='h3'>
									{t('tickets.현장발권')}
								</Typography>
							</Button>
						)}
					</Stack>
				)}
			</Stack>

			{kioskInfo && storeId && (
				<KioskInfoWrapper
					storeId={storeId}
					kioskInfo={kioskInfo}
				/>
			)}

			{(isCardInserted || salesStatus !== 'OPENED') && (
				<Backdrop open={true}>
					<Button
						variant='outlined'
						sx={{ pointerEvents: 'none', cursor: 'none', backgroundColor: '#ddd' }}
						color='inherit'>
						{isCardInserted && (
							<Typography
								variant={'h4'}
								color={'inherit'}>
								<WarningOutlined />
								결제 단말기에서 카드를 제거해주세요.
							</Typography>
						)}
						{salesStatus === 'CLOSED' && (
							<Typography
								variant={'h4'}
								color={'inherit'}>
								키오스크 영업 마감되었습니다
							</Typography>
						)}
						{salesStatus === 'BEFORE_OPEN' && (
							<Typography
								variant={'h4'}
								color={'inherit'}>
								키오스크 영업 전입니다
							</Typography>
						)}
						{salesStatus === 'PAUSED' && (
							<Stack>
								<Typography
									variant={'h4'}
									color={'inherit'}>
									키오스크 영업 정지 상태입니다.
								</Typography>
							</Stack>
						)}
					</Button>
				</Backdrop>
			)}
		</전광판Image>
	);
};

const 전광판Image = styled(Box)<{
	backgroundImage?: string;
	// salesStatus?: 'CLOSED' | 'BEFORE_OPEN';
}>(({ backgroundImage }) => ({
	position: 'relative',
	backgroundImage: `url(${backgroundImage || entry})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '100%',
	height: '100%',
}));

const KioskInfoWrapper = ({
	storeId,
	kioskInfo,
}: {
	storeId?: number;
	kioskInfo: KioskInfoType;
}) => {
	const { data: storeInfo } = useQuery({
		...AdminStoreInfoState.keys.simple(storeId),
		refetchOnWindowFocus: true,
	});

	return (
		<Stack
			position={'absolute'}
			direction={'row'}
			width='100%'
			justifyContent={'space-between'}
			top={0}
			left={0}
			sx={{ px: 2, py: 2 }}>
			<I18NSelectComponent />

			<Typography
				variant='subtitle1'
				color={'text.secondary'}>
				{storeInfo?.name ?? ''} | {KioskTypeLabel[kioskInfo.type]} | {kioskInfo.name}
			</Typography>
		</Stack>
	);
};

const I18NSelectComponent = () => {
	const i18nUsing = KioskElectronStoreState.useKioskMainPageLocalSettings().data?.i18n;

	const { t, kioskI18N } = useKioskTranslation();
	const changeLanguage = useKioskChangeLanguague();
	// 항상 초기화되도록 조치
	useEffect(() => {
		changeLanguage('ko');
	}, []);

	return (
		<Stack
			spacing={2}
			direction={'row'}>
			{i18nUsing?.eng && (
				<StyledAvatar
					variant='square'
					selected={kioskI18N === 'ko'}
					onClick={() => changeLanguage('ko')}
					src={SouthKoreaFlag}
				/>
			)}
			{i18nUsing?.eng && (
				<StyledAvatar
					variant='square'
					selected={kioskI18N === 'eng'}
					onClick={() => changeLanguage('eng')}
					src={EnglishFlag}
				/>
			)}
			{i18nUsing?.jpn && (
				<StyledAvatar
					variant='square'
					selected={kioskI18N === 'jpn'}
					onClick={() => changeLanguage('jpn')}
					src={JapanFlag}
				/>
			)}
			{i18nUsing?.chn && (
				<StyledAvatar
					variant='square'
					selected={kioskI18N === 'chn'}
					onClick={() => changeLanguage('chn')}
					src={ChinaFlag}
				/>
			)}
		</Stack>
	);
};

const StyledAvatar = styled(Avatar)<{ selected: boolean }>(({ theme, selected }) => ({
	borderRadius: 8,
	width: 120,
	height: 75,
	filter: selected ? undefined : 'grayscale(100%)',
}));
