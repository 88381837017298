import { CreditCardOutlined, MobileOutlined } from '@ant-design/icons';
import { isOnElectron } from '@kinderlabs-pos/ipc/react';
import {
	EasyPayMethodType,
	EasyPayMethodTypeArray,
	KioskInfoType,
	PaymentMethodTypeLabel,
	has간편결제지원,
} from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { KioskNumberBoard } from 'apps/kiosk-main/src/components/KioskNumberBoard';
import { useAtomValue } from 'jotai';

import KAKAOPAYImg from 'assets/easypay/kakaopay.png';
import SSGPAYImg from 'assets/easypay/ssgpay.png';
import SEOULPAYImg from 'assets/easypay/seoulpay.png';
import APPCARDImg from 'assets/easypay/appcard.png';
import ALIPAYImg from 'assets/easypay/alipay.png';
import WECHATPAYImg from 'assets/easypay/wechatpay.png';
import NAVERPAYImg from 'assets/easypay/naverpay.png';
import BCPAYBOOCImg from 'assets/easypay/bcpaybooc.png';
import ZEROPAYImg from 'assets/easypay/zeropay.png';
import PAYCOImg from 'assets/easypay/payco.png';
import { KioskPaymentMethodType } from '../../KioskMainPageType';

export const KioskEasyPaySelectSection = ({
	open,
	closeDialog,
	goBack,
	kioskInfo,
	selectPayment,
}: {
	goBack: () => void;
	kioskInfo: KioskInfoType;
	selectPayment: (method: KioskPaymentMethodType) => void;
} & XlDialogProps) => {
	const totalToPay = useAtomValue(OrderState.aggregate.cart).totalToPay;
	const LogoImgs = [
		KAKAOPAYImg,
		SSGPAYImg,
		SEOULPAYImg,
		APPCARDImg,
		ALIPAYImg,
		WECHATPAYImg,
		NAVERPAYImg,
		BCPAYBOOCImg,
		ZEROPAYImg,
		PAYCOImg,
	];

	return (
		<Stack height={'100%'}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>{'간편결제 수단을 선택하세요'}</Typography>
			</Stack>
			<Stack
				flex={1}
				height={'100%'}
				width={'100%'}
				alignItems={'center'}
				py={5}
				spacing={5}>
				<Stack
					width={'100%'}
					alignItems={'center'}>
					<Typography variant='h4'>
						<Typography
							component={'span'}
							color={'text.secondary'}>
							결제금액
						</Typography>
						{`ㅤ${numberWithCommasAnd원(totalToPay)}`}
					</Typography>
				</Stack>
				<Grid2
					width={'100%'}
					container
					spacing={2}>
					{EasyPayMethodTypeArray.map((value, idx) => (
						<Grid2 xs={6}>
							<Button
								disabled={totalToPay === 0 || !has간편결제지원[kioskInfo.vanCompany][value]}
								onClick={() => selectPayment(value)}
								variant='outlined'
								sx={{ height: 100 }}
								fullWidth>
								<Stack
									direction={'row'}
									alignItems={'center'}
									spacing={1}
									width={1}
									pl={'30px'}
									justifyContent={'flex-start'}>
									<EasyPayLogo name={LogoImgs[idx]} />
									<Typography variant='subtitle1'>{PaymentMethodTypeLabel[value]}</Typography>
								</Stack>
							</Button>
						</Grid2>
					))}
				</Grid2>
			</Stack>
			<Stack
				width={'100%'}
				direction={'row'}
				py={3}
				spacing={3}>
				<Button
					variant='outlined'
					fullWidth
					onClick={goBack}>
					돌아가기
				</Button>
			</Stack>
		</Stack>
	);
};

const EasyPayLogo = styled(Box)(({ name }: { name: string }) => ({
	backgroundImage: `url(${name})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	width: '5rem',
	height: '5rem',
}));
